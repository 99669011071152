<script lang="ts" setup>
import { MediaCommunitySkinElement, MediaPlayerElement, type CommunitySkinTranslations } from "vidstack";
import { VTTCue } from "media-captions";
import { defineCustomElements as customs } from "vidstack/elements";
import { nextTick, ref, watch, shallowReactive } from "vue";

const GERMAN_TRANSLATION: CommunitySkinTranslations = {
  Audio: 'Audio',
  Auto: 'Automatisch',
  Captions: 'Untertitel',
  Chapters: 'Kapitel',
  Default: 'Standard',
  Mute: 'Stumm',
  Normal: 'Normal',
  Off: 'Stopp',
  Pause: 'Pause',
  Play: 'Play',
  Speed: 'Geschwindigkeit',
  Quality: 'Qualität',
  Settings: 'Einstellungen',
  Unmute: 'Stummschaltung aufheben',
  'Seek Forward': 'Vorwärts',
  'Seek Backward': 'Rückwärts',
  'Closed-Captions On': 'Untertitel an',
  'Closed-Captions Off': 'Untertitel aus',
  'Enter Fullscreen': 'Vollbild einschalten',
  'Exit Fullscreen': 'Vollbild ausschalten',
  'Enter PiP': 'Bild-im-Bild einschalten',
  'Exit PiP': 'Bild-im-Bild ausschalten',
};

const CHAPTER_TITLE_DELAY = 6000  // 6 seconds

customs();


interface Chapter {
  sorting: number;
  cue: VTTCue;
  active: boolean;
  metadata: ChapterMetadata;
}

interface ChapterMetadata {
  thumbnailUrl: string;
  showTitleOnStart: boolean;
  isInitialChapter: boolean;
}

const props = defineProps<{
  src: string;
  chaptersSrc?: string;
  chaptersMetadata: string;
}>();

const chaptersMetadata: Array<ChapterMetadata> = JSON.parse(props.chaptersMetadata)
const chapters = ref<Array<Chapter>>([]);

const player = ref<MediaPlayerElement | null>(null);
const skin = ref<MediaCommunitySkinElement | null>(null);

let currentOverlayHeading = ref<string | null>(null)
let currentChapter = ref<Chapter | null>(null)

watch(player, async (player) => {
  await nextTick();

  if (player) {
    player.addEventListener("loaded-metadata", () => onTrackUpdates(player));
    player.addEventListener("text-tracks-change", () => onTrackUpdates(player));
  }
});

const humanReadableLength = (chapter: Chapter) => {
  const cueLength = chapter.cue.endTime - chapter.cue.startTime
  return cueLength < 60
    ? "1 min"
    : `${Math.round(cueLength / 60)} min`
}

const onTrackUpdates = (player: MediaPlayerElement) => {
  for (const track of player.textTracks) {
    if (track.kind !== "chapters") continue;

    track.addEventListener("cue-change", () => onActiveCueUpdate(player));
    track.addEventListener("load", () => onTrackLoadUpdate(player));
    track.addEventListener("add-cue", () => onTrackLoadUpdate(player));
    track.addEventListener("remove-cue", () => onTrackLoadUpdate(player));
  }
};

const onTrackLoadUpdate = (player: MediaPlayerElement) => {
  chapters.value = []

  for (const track of player.textTracks) {
    if (track.kind !== "chapters") continue;

    for (const [ indx, cue ] of track.cues.entries()) {
      const newChapter = {
        sorting: indx + 1,
        cue,
        active: false,
        metadata: chaptersMetadata[indx]
      }
      chapters.value.push(newChapter)

      if (newChapter.metadata.isInitialChapter) {
        jumpToChapter(newChapter)
      }
    }
  }

  onActiveCueUpdate(player)
}

const onActiveCueUpdate = (player: MediaPlayerElement) => {
  for (const chapter of chapters.value) chapter.active = false;

  for (const track of player.textTracks) {
    if (track.kind !== "chapters") continue;

    for (const chapter of chapters.value) {
      for (const activeCue of track.activeCues) {
        if (chapter.cue === activeCue) {
          chapter.active = true;
          currentChapter.value = chapter
          updateBreadcrumbs(chapter)
          if (chapter.metadata.showTitleOnStart) {
            showChapterTitle(chapter)
          }
        }
      }
    }
  }
};

const showChapterTitle = (chapter: Chapter) => {
  currentOverlayHeading.value = chapter.cue.text
  setTimeout(() => {
    currentOverlayHeading.value = null
  }, CHAPTER_TITLE_DELAY)
}

const jumpToChapter = (chapter: Chapter) => {
  if(player.value) {
    player.value.currentTime = chapter.cue.startTime;
  }
}

const updateBreadcrumbs = (activeChapter: Chapter) => {
  const lastBreadcrumb = document.querySelector(".layout-breadcrumbs a:last-of-type")
  if (lastBreadcrumb) {
    lastBreadcrumb.textContent = activeChapter.cue.text
    lastBreadcrumb.setAttribute('href', `?clip=${activeChapter.sorting}`)
  }
}

watch(skin, (skin) => {
  if(skin) {
    skin.translations = GERMAN_TRANSLATION;
  }
})
</script>

<template>
  <slot name="header"
    v-if="currentChapter"
    v-bind:activeChapter="currentChapter"
    v-bind:humanReadableLength="() => humanReadableLength(currentChapter)"
  ></slot>

  <div>
    <media-player ref="player" v-bind:src="src">
      <media-outlet>
        <media-poster></media-poster>
        <track
          v-if="chaptersSrc"
          v-bind:src="chaptersSrc"
          srclang="de"
          kind="chapters"
          default
        />
      </media-outlet>
      <media-community-skin ref="skin"></media-community-skin>
      <div v-if="currentOverlayHeading" class="video-player-overlay">
        <div class="video-player-overlay__content">
          {{ currentOverlayHeading }}
        </div>
      </div>
    </media-player>
  </div>

  <slot name="content"></slot>

  <div>
    <div class="chapters">
      <div
        class="chapter"
        v-for="(chapter, indx) in chapters"
        v-bind:key="chapter.cue.startTime"
      >
        <slot name="chapter"
          v-bind:chapter="chapter"
          v-bind:indx="indx"
          v-bind:jump="() => jumpToChapter(chapter)"
          v-bind:humanReadableLength="() => humanReadableLength(chapter)"
        ></slot>
      </div>
    </div>
  </div>
</template>
