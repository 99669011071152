<template>
  <div class="vue__transaction-pending-handler">
    <!-- dummy element to execute javascript logic -->
    {{displayText}}
  </div>
</template>

<style lang="scss" scoped>
.vue__transaction-pending-handler {
  @import "~stylesheets/application/colors";
  color: $color-green;
}
</style>

<script>
import { ref, onUnmounted, unref, watch } from "vue";

import backend from "apps/shared/backend";
import periodicallyTick from "../compositions/periodically-tick";

// polling interval in ms
const POLLING_INTERVAL = 5000;

export default {
  props: {
    pollingPath: {
      type: String,
    },
  },
  setup(props) {
    const displayText = ref(null);

    const { tick, cancelTick } = periodicallyTick(POLLING_INTERVAL);

    onUnmounted(() => {
      cancelTick();
    });

    let pollingATM = false;
    watch(tick, async () => {
      // only poll if the last polling is done
      if (pollingATM) return;
      if (!unref(props.pollingPath)) return;

      pollingATM = true;
      try {
        console.debug("polling server for current status");
        const result = await backend(unref(props.pollingPath), "GET");

        if (!result.data.transaction.is_pending) {
          displayText.value =
            "Der Zahlungsvorgang wurde abgeschlossen, bitte laden Sie die Seite erneut!";
          console.log(
            "no longer pending status --> reload page",
            result.data.transaction
          );
          cancelTick();
          location.reload();
        } else {
          console.debug("still pending", result.data.transaction);
        }
      } finally {
        pollingATM = false;
      }
    });

    return {
      displayText,
      tick,
      cancelTick,
    };
  },
};
</script>
