<script lang="ts" setup>
import backend from "apps/shared/backend";
import { ref } from "vue";

const props = defineProps<{
  filledStarPath: string;
  emptyStarPath: string;
  rateEpisodePath: string;
  rating: string;
  maxRating: string;
}>();

const starCount = Number(props.maxRating);
const currentRating = ref<number>(Number(props.rating));
const submittingRating = ref(false);

const setRating = async (rating: number) => {
  if (rating != currentRating.value) {
    const result = await backend(
      props.rateEpisodePath,
      "POST",
      {
        rating: rating,
      }
    );

    if (result.data.success) {
      currentRating.value = rating;
    }
  }
}

const imagePath = (indx: number) => {
  return indx <= currentRating.value
    ? props.filledStarPath
    : props.emptyStarPath
}

</script>

<template>
  <span class="star-rating">
    <img
      v-for="indx in starCount"
      v-bind:key="indx"
      v-bind:src="imagePath(indx)"
      v-on:click="setRating(indx)"
    />
  </span>
</template>
