<template>
  <div class="vue__payment-hdp-form">
    <form class="heidelpayUI form" novalidate ref="form">
      <slot></slot>
      <div class="field">
        <button
          ref="elementRef"
          class="heidelpayUI primary button fluid"
          type="submit"
        >Kostenpflichtig bestellen</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, watch } from "vue";

import useElementDisabler from "../compositions/use-element-disabler";

export default {
  setup(props, { emit }) {
    const { elementRef, elementControl } = useElementDisabler();

    const form = ref(null);

    watch(form, (form, oldForm) => {
      if (!form) return;

      form.addEventListener("submit", function (event) {
        event.preventDefault();
        emit("submit", elementControl);
      });
    });

    return {
      // tag-refs:
      form,

      // element refs:
      elementRef,
    };
  },
};
</script>
