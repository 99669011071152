<template>
  <div class="vue__payment-hdp-sepa">
    <h4>SEPA Lastschrift-Mandat (Bankeinzug)</h4>
    <p>Ich ermächtige BDC Service GmbH, Zahlungen von meinem Konto mittels SEPA Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von BDC Service GmbH auf mein Konto gezogenen SEPA Lastschriften einzulösen.</p>
    <p>Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrags verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.</p>
    <p>Für den Fall der Nichteinlösung der Lastschriften oder des Widerspruchs gegen die Lastschriften weise ich meine Bank unwiderruflich an, BDC Service GmbH oder Dritten auf Anforderung meinen Namen, Adresse und Geburtsdatum vollständig mitzuteilen.</p>
    <payment-hdp-form v-on:submit="submit">
      <div v-bind:id="ibanId" class="field">
        <!-- The IBAN field UI Element will be inserted here -->
      </div>
    </payment-hdp-form>
  </div>
</template>

<script>
import { onMounted, unref } from "vue";

import createHeidelpayInstance from "../../lib/create-heidelpay-instance";
import createUniqueId from "../../lib/create-unique-id";

export default {
  setup(props, { emit }) {
    // Creating a credit card instance
    const heidelpayInstance = createHeidelpayInstance();
    const Sepa = heidelpayInstance.SepaDirectDebit();

    const ibanId = createUniqueId();

    onMounted(() => {
      // Rendering input fields
      Sepa.create("sepa-direct-debit", {
        containerId: unref(ibanId),
        onlyIframe: false,
      });
    });

    const submit = async (elementControl) => {
      try {
        elementControl(false);
        const result = await Sepa.createResource();
        emit("pay", result, elementControl);
      } catch (error) {
        console.error("Error while submitting heidelpay form", error);
        elementControl(true);
      }
    };

    return {
      // static data:
      ibanId,

      // methods:
      submit,
    };
  },
};
</script>
