import { createApp } from "vue";

import ImportAll from "../../wp_utils/import_all";
import ActionTextFix from "./action-text-fix";
import dispatchVueReady from "./dispatch-vue-ready"

let listenersAdded = false;

// Create vue instance
export default function(selector, componentContext){
  // Debounce listeners registration
  if(listenersAdded) {
    return
  } else {
    listenersAdded = true;
  }

  document.addEventListener(
    "DOMContentLoaded",
    eventHandlerMaker(selector, componentContext)
  );
  document.addEventListener(
    "turbo:load",
    eventHandlerMaker(selector, componentContext)
  );
}

const eventHandlerMaker = function(selector, componentContext) {
  return () => {
    console.debug("Trying to load Vue.js");

      // Check if element exists
      const appElement = document.querySelector(selector)
      if(!appElement) {
        console.debug(`Vue.js will not be loaded because selector ${selector} not found on page`);
        dispatchVueReady();
        return;
      } else if (appElement.dataset.alreadyVued) {
        console.debug(`Vue.js will not be loaded because selector ${selector} is already a Vue.js instance`);
        return;
      } else {
        appElement.dataset.alreadyVued = true;
      }

      // Create an "ActionText-Fixer"
      const actionTextFixer = new ActionTextFix();
      actionTextFixer.prepareValues();

      // Create app
      const app = createApp({
        mounted() {
          actionTextFixer.startActionText();
        }
      });

      actionTextFixer.prepareApp(app);

      if(componentContext) {
        // Load all vue-components
        console.debug("Loading components for Vue.js");
        const components = {};
        ImportAll(components, componentContext());
        Object.keys(components).forEach(key => app.component(key, components[key]));
      }

      // Mount application
      app.mount(selector);
      setTimeout( () => {
        dispatchVueReady();
      }, 0 );
  }
};
