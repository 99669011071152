let eventFired = false;

export const vueReadyEventName = 'vue-ready';
export default function() {
  const event = createNewEvent(vueReadyEventName);
  document.dispatchEvent(event);
  eventFired = true;
}
export function vueReadyAlreadyFired() {
  return !!eventFired;
}

// Fix for IE (sigh...)
function createNewEvent(eventName) {
  let event;
  if (typeof(Event) === 'function') {
      event = new Event(eventName);
  } else {
      event = document.createEvent('Event');
      event.initEvent(eventName, true, true);
  }
  return event;
}

