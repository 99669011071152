import Glide, { Swipe, Controls, Images, Autoplay } from '@glidejs/glide/dist/glide.modular.esm'

import {vueReadyAlreadyFired, vueReadyEventName} from "apps/shared/dispatch-vue-ready";

export default function(selector) {
    if(vueReadyAlreadyFired()) { initGlide(selector) }
    else {
      document.addEventListener(vueReadyEventName, () => {
        initGlide(selector);
      })
    }
}

const initGlide = function(selector) {
  // See: https://github.com/glidejs/glide/issues/469
  // for chrome error
  if(document.querySelector(selector)) {
    const glide = new Glide(selector, {
      autoplay: 10000,
    }).mount({
      Swipe,
      Controls,
      Images,
      Autoplay,
    });
  }
}
