<template>
  <div class="vue__payment-hdp-paypal">
    <div class="field">
      <button
        ref="elementRef"
        v-on:click="submit"
        id="paypal"
        class="heidelpayUI primary button fluid"
      >Mit PayPal kostenpflichtig bestellen</button>
    </div>
  </div>
</template>

<script>
import { onMounted, unref } from "vue";

import createHeidelpayInstance from "../../lib/create-heidelpay-instance";
import createUniqueId from "../../lib/create-unique-id";
import useElementDisabler from "../compositions/use-element-disabler";

export default {
  setup(props, { emit }) {
    // Creating a credit card instance
    const heidelpayInstance = createHeidelpayInstance();
    const Paypal = heidelpayInstance.Paypal();

    const { elementRef, elementControl } = useElementDisabler();

    const submit = async () => {
      try {
        elementControl(false);
        const result = await Paypal.createResource();
        emit("pay", result, elementControl);
      } catch (error) {
        console.error("Error while submitting heidelpay form", error);
        elementControl(true);
      }
    };

    return {
      // methods:
      submit,

      // element refs:
      elementRef,
    };
  },
};
</script>
