import jQuery from "jquery";

export default function(buttonSelector, targetSelector) {
  jQuery(() => {
    const button = jQuery(buttonSelector);
    const target = jQuery(targetSelector);

    button.on('click', () => {
      target[0].requestFullscreen();
    })

  });
}
