import { ref } from "vue";

export default function() {
  const elementRef = ref(null);

  const elementControl = async (state) => {
    const stateBool = !state;
    if(elementRef.value) {
      if(stateBool) {
        elementRef.value.setAttribute('disabled', stateBool);
      } else {
        elementRef.value.removeAttribute('disabled');
      }
    }
  }

  return {
    elementRef,
    elementControl,
  }
}
