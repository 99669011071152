import jQuery from "jquery";

export default function(checkboxSelector, targetSelector) {
  jQuery(document).one('turbo:load', () => {
    const checkbox = jQuery(checkboxSelector);
    const target = jQuery(targetSelector);

    disableLink(target, !checkbox.is(':checked'));
    target[0].addEventListener('click', (event) => {
      if(target.hasClass('button--disabled')) {
        event.stopPropagation();
        event.preventDefault();

        const label = getLabelForInput(checkbox)
        if(label) {
          label.addClass('label--highlight');
          label.addClass('animation__shake');
          setTimeout(() => {
            label.removeClass('label--highlight');
            label.removeClass('animation__shake');
          }, 3000);
        }

      }
    }, true); // get event in capture phase and not in "usual" bubbling phase

    checkbox.on('change', (event) => {
      disableLink(target, !checkbox.is(':checked'));
    })
  });
}

const disableLink = (target, disable = true) => {
  if(disable) {
    target.addClass('button--disabled');
  } else {
    target.removeClass('button--disabled');
  }
};

const getLabelForInput = (input) => {
  return jQuery("label[for='" + input.attr('id') + "']");
}
