import getMetaTag from "apps/shared/get-meta-tag"

export default function() {
  // Remove sandbox hint - if exists
  for(const element of document.querySelectorAll('.heidelpaySandboxNotify')) {
    element.remove();
  }

  // Retrieve public key from meta tag
  const heidelpayPublicKey = getMetaTag('heidelpay-public-key');
  return new heidelpay(heidelpayPublicKey);
};
