
export default class ActionTextCustomizer {
  #trix = null; // the Trix instance

  constructor(trixInstance) {
    this.#trix = trixInstance;
  }

  customize() {
    this._allowRelativeLinks();
  }

  _allowRelativeLinks() {
    addEventListener("trix-initialize", event => {
      const { toolbarElement } = event.target
      const inputElement = toolbarElement.querySelector("input[name=href]")
      inputElement.type = "text"
      inputElement.pattern = "(https?://|/|mailto:).+"
    });
  }
}
