import jQuery from "jquery"

import ParameterWatcher from "apps/shared/parameter-watcher";

const defined = {};
const PARAMETER_NAME = 'sortierung'

export default function(selector, defaultValue) {
  if(defined[selector]) { return }
  defined[selector] = true;

  jQuery(document).on('turbo:load', () => {
    const watcher = new ParameterWatcher(PARAMETER_NAME);
    const currentParameterValue = watcher.getCurrentValue();
    const select = jQuery(selector);
    select.val(currentParameterValue || defaultValue);
    select.on('change', () => {
      watcher.updateCurrentValue(select.val());
    });
  })
}
