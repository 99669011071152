<template>
  <div class="vue__payment-manager">
    <h1>Bezahlung</h1>

    <div class="payment-manager__grand-total">
      <div class="shopping-cart__summary-grand-total-label">Gesamtpreis:</div>
      <div>{{ totalWithCurrencyProp }}</div>
    </div>
    <div class="payment-manager__vat">
      inklusive gesetzlicher Mehrwertsteuer
    </div>

    <h2>Rechnungsadresse</h2>
    <template v-if="(user.addresses || []).length <= 0">
      <p>
        Ihrem Profil ist aktuell <b>keine Adresse</b> zugeordnet. Bitte legen
        Sie zunächst eine Adresse unter
        <a v-bind:href="userProfilePathProp">Benutzerdaten</a> an.
      </p>
    </template>
    <template v-else>
      <p>Wählen Sie die gewünschte Rechnungsadresse aus:</p>
      <label
        class="payment-manager__address"
        v-for="address in user.addresses"
        v-bind:key="address.id"
        v-bind:for="'address_' + address.id"
      >
        <input
          v-bind:value="address.id"
          v-model="selectedAddress"
          type="radio"
          v-bind:id="'address_' + address.id"
        />
        <div>
          <div class="payment-manager__address-kind">
            {{ mapAddressKind(address.kind) }}
          </div>
          <div>{{ user.full_name }}</div>
          <div v-if="address.addendum">{{ address.addendum }}</div>
          <div v-if="address.street">{{ address.street }}</div>
          <div v-if="address.city">{{ address.zip }} {{ address.city }}</div>
          <div v-if="address.country">{{ address.country }}</div>
        </div>
      </label>
    </template>

    <template v-if="selectedAddress">
      <h2 class="heading--distanced-content-small">AGB</h2>
      <label>
        <input v-model="acceptedAGB" type="checkbox" />
        Ich habe die
        <a target="_blank" href="/inhalte/agb"
          >Allgemeinen Geschäftsbedingungen (AGB)</a
        >
        und die
        <a target="_blank" href="/inhalte/widerruf"
          >Informationen zum Widerruf</a
        >
        gelesen und akzeptiere sie
      </label>
    </template>

    <template v-if="selectedAddress && acceptedAGB">
      <h2>Zahlungsmittel</h2>
      <p>
        Wählen Sie ein Zahlungsmittel aus mit dem Sie den Gesamtbetrag von
        {{ totalWithCurrencyProp }} bezahlen möchten:
      </p>
      <div class="payment-manager__payments-container">
        <div
          class="payment-manager__payment-selector"
          v-for="paymentMethod in availablePaymentMethods"
          v-bind:key="paymentMethod.key"
          v-on:click="selectPayment(paymentMethod)"
          v-bind:class="{
            'payment-manager__payment-selector--active':
              paymentMethod == selectedPaymentMethod,
          }"
        >
          {{ paymentMethod.label }}
        </div>
      </div>

      <template v-if="selectedPaymentMethod">
        <h2>{{ selectedPaymentMethod.headline }}</h2>
        <component v-on:pay="pay" v-bind:is="selectedPaymentMethod.component" />
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.vue__payment-manager {
  .payment-manager__grand-total {
    display: flex;
    justify-content: space-between;

    font-size: 18px;
    font-weight: bold;
  }

  h2 {
    margin-top: 20px;
    font-size: 22px;
  }

  .payment-manager__vat {
    font-size: 14px;
    text-align: right;
  }

  .payment-manager__payments-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .payment-manager__payment-selector {
    @import "~stylesheets/application/colors";
    border: 1px solid $color-black;
    padding: 15px;
    cursor: pointer;
    user-select: none;
    margin-right: 15px;
    margin-bottom: 15px;
    text-align: center;

    width: 120px;
    overflow: hidden;
  }

  .payment-manager__payment-selector--active {
    @import "~stylesheets/application/colors";
    border: 1px solid $color-blue;
    background-color: $color-blue;
    color: $color-white;
  }

  .payment-manager__address {
    display: flex;

    input {
      margin-right: 15px;
      margin-top: 5px;
    }

    padding: 15px 10px;
    margin-bottom: 15px;

    @import "~stylesheets/application/colors";
    background-color: $color-grey-10;
  }

  .payment-manager__address-kind {
    font-weight: bold;
    margin-bottom: 5px;
  }

  margin-bottom: 20px;
}
</style>

<script>
import { toRefs, ref, unref } from "vue";

import backend from "apps/shared/backend";
import getMetaTag from "apps/shared/get-meta-tag";

export default {
  props: {
    totalWithCurrency: {
      type: String,
      default: null,
    },
    recursiveUpdatedAt: {
      // to be sent to the server to check if the shopping cart changed meanwhile
      type: String,
      default: null,
    },
    user: {
      type: Object,
    },
    userProfilePath: {
      type: String,
    },
  },
  setup(props) {
    const { total, totalWithCurrency, user, userProfilePath } = toRefs(props);

    const acceptedAGB = ref(false);

    const childComponent = ref(null);
    const selectedPaymentMethod = ref(null);
    const availablePaymentMethods = ref([
      {
        key: 1,
        label: "Kreditkarte",
        headline: "Kreditkarte",
        component: "payment-hdp-creditcard",
        data: {},
      },
      {
        key: 2,
        label: "SEPA Lastschrift",
        headline: "SEPA Lastschrift",
        component: "payment-hdp-sepa",
        data: {},
      },
      {
        key: 3,
        label: "Sofortüberweisung",
        headline: "",
        component: "payment-hdp-sofort",
        data: {},
      },
      {
        key: 4,
        label: "PayPal",
        headline: "",
        component: "payment-hdp-paypal",
        data: {},
      },
    ]);

    const selectPayment = (paymentMethod) => {
      selectedPaymentMethod.value = paymentMethod;
    };

    const selectedAddress = ref(null);
    const mapAddressKind = (kind) => {
      switch (kind) {
        case "personal":
          return "Persönliche Anschrift";
        case "practice":
          return "Praxisanschrift";
        case "clinic":
          return "Klinikanschrift";
        default:
          return "Andere Anschrift";
      }
    };

    const pay = async (result, elementControl) => {
      try {
        const requestResult = await backend(
          getMetaTag("pay_update_collections_path"),
          "PATCH",
          {
            heidelpay: result,
            recursiveUpdatedAt: props.recursiveUpdatedAt,
            selectedAddress: unref(selectedAddress),
          }
        );
        if (requestResult?.data?.redirectUrl) {
          window.location.href = requestResult?.data?.redirectUrl; // redirect away
        }
      } finally {
        if (elementControl) {
          console.debug("Reenable element");
          elementControl(true);
        }
      }
    };

    return {
      // data:
      // Vue 3 / Vue 2 with composition: we need to name it different as
      // the prop. Vue 3 does not need this, but vue 2 with composition cannot handle it
      // with the same name
      totalWithCurrencyProp: totalWithCurrency,
      availablePaymentMethods,
      selectedPaymentMethod,
      userProp: user,

      selectedAddress,

      userProfilePathProp: userProfilePath,

      acceptedAGB,

      // methods:
      selectPayment,
      pay,
      mapAddressKind,
    };
  },
};
</script>
