<template>
  <div class="vue__payment-hdp-creditcard">
    <payment-hdp-form v-on:submit="submit">
      <div class="field">
        <div v-bind:id="numberId" class="heidelpayInput">
          <!-- Card number UI Element will be inserted here. -->
        </div>
      </div>
      <div class="two fields">
        <div class="field ten wide">
          <div v-bind:id="expiryId" class="heidelpayInput">
            <!-- Card expiry date UI Element will be inserted here. -->
          </div>
        </div>
        <div class="field six wide">
          <div v-bind:id="cvcId" class="heidelpayInput">
            <!-- Card CVC UI Element will be inserted here. -->
          </div>
        </div>
      </div>
      <div class="field">
        <div v-bind:id="holderId" class="heidelpayInput">
          <!-- Card holder UI Element will be inserted here. -->
        </div>
      </div>
    </payment-hdp-form>
  </div>
</template>

<script>
import { onMounted, unref } from "vue";

import createHeidelpayInstance from "../../lib/create-heidelpay-instance";
import createUniqueId from "../../lib/create-unique-id";

export default {
  setup(props, { emit }) {
    // Creating a credit card instance
    const heidelpayInstance = createHeidelpayInstance();
    const Card = heidelpayInstance.Card();

    const numberId = createUniqueId();
    const expiryId = createUniqueId();
    const cvcId = createUniqueId();
    const holderId = createUniqueId();

    onMounted(() => {
      // Rendering input fields
      Card.create("number", {
        containerId: unref(numberId),
        onlyIframe: false,
      });
      Card.create("expiry", {
        containerId: unref(expiryId),
        onlyIframe: false,
      });
      Card.create("cvc", {
        containerId: unref(cvcId),
        onlyIframe: false,
      });
      Card.create("holder", {
        containerId: unref(holderId),
        onlyIframe: false,
      });
    });

    const submit = async (elementControl) => {
      try {
        elementControl(false);
        const result = await Card.createResource();
        emit("pay", result, elementControl);
      } catch (error) {
        console.error("Error while submitting heidelpay form", error);
        elementControl(true);
      }
    };

    return {
      // static data:
      numberId,
      expiryId,
      cvcId,
      holderId,

      // methods:
      submit,
    };
  },
};
</script>
