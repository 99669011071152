<template>
  <div
    class="vue__slide-presentation"
    ref="rootElement"
    v-on:contextmenu.prevent
    tabindex="-1"
    v-on:keydown.right="navigateNext"
    v-on:keydown.left="navigatePrev"
  >
    <div class="slide-presentation__slides">
      <template v-if="currentSlide.type === 'video'"
        ><video
          controls
          controlsList="nodownload"
          v-on:contextmenu.prevent
          v-bind:src="currentSlide.file"
        ></video
      ></template>
      <template v-else>
        <div class="slide-presentation__overlay"></div>
        <img v-if="currentSlide" v-bind:src="currentSlide.file" />
        <div
          class="slide-presentation__fullscreen-image"
          v-bind:style="{ 'background-image': `url('${currentSlide.file}')` }"
        ></div>
      </template>
    </div>
    <div class="slide-presentation__controls">
      <div class="slide-presentation__control-group">
        <div
          v-on:click="toggleFullscreen"
          class="slide-presentation__fullscreen-button"
        >
          <i
            v-if="isFullscreen"
            class="icon-collection ic-fullscreen-close"
          ></i>
          <i v-else class="icon-collection ic-fullscreen-open"></i>
        </div>
        <div class="slide-presentation__progress">
          <input
            type="number"
            class="position-input"
            v-bind:value="currentPosition + 1"
            v-on:input="onNewPosition"
          />
          / {{ slidesProp.length }}
        </div>
      </div>
      <div class="slide-presentation__control-group">
        <div
          class="arrow-button arrow-button--left"
          v-bind:class="{ 'arrow-button--disabled': currentPosition === 0 }"
          v-on:click="navigatePrev"
        >
          PREV
        </div>
        <div
          class="arrow-button arrow-button--right"
          v-bind:class="{
            'arrow-button--disabled': currentPosition === slidesProp.length - 1,
          }"
          v-on:click="navigateNext"
        >
          NEXT
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
video {
  width: 100%;
}

.position-input {
  &,
  &:focus {
    @import "~stylesheets/application/colors";
    outline: none;
    margin: 0;
    padding: 0;

    width: 20px;
    border: none;
    background-color: transparent;
    color: $color-grey-70;
    margin-right: 2px;
    border-radius: none;

    text-align: right;
  }
}

// hide arrows on number field
/* Chrome, Safari, Edge, Opera */
input.position-input::-webkit-outer-spin-button,
input.position-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input.position-input {
  -moz-appearance: textfield;
}

.slide-presentation__slides {
  @import "~stylesheets/application/colors";
  border: 7px solid $color-grey-20;
  border-radius: 5px 5px 0 0;

  line-height: 0;

  img {
    width: 100%;
  }

  position: relative;

  &,
  .slide-presentation__fullscreen-image,
  img {
    user-select: none;
  }

  .slide-presentation__fullscreen-image {
    display: none;
  }

  @media print {
    // hide content in print screen
    display: none;
  }
}

.slide-presentation__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: transparent;

  z-index: 999;
}

.slide-presentation__fullscreen-image {
  height: 100%;
  width: 100%;

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.slide-presentation__controls {
  @import "~stylesheets/application/colors";
  background-color: $color-grey-10;

  padding: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slide-presentation__control-group {
  display: flex;
}

.slide-presentation__fullscreen-button {
  @import "~stylesheets/application/colors";
  background-color: $color-grey-70;
  color: $color-white;

  width: 23px;
  height: 23px;

  border-radius: 2px;

  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 15px;
  }

  cursor: pointer;
}

.slide-presentation__progress {
  @import "~stylesheets/application/colors";
  color: $color-grey-70;

  display: flex;
  align-items: center;

  font-size: 12px;
  margin-left: 10px;
}

.vue__slide-presentation:focus {
  outline: none;
}

.vue__slide-presentation:fullscreen {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  .slide-presentation__slides {
    box-sizing: border-box;
    text-align: center;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      display: none;
    }

    video {
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
    }

    .slide-presentation__fullscreen-image {
      display: block;
    }
  }

  .slide-presentation__controls {
    box-sizing: border-box;
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 54px;
  }
}
</style>

<style lang="scss" scoped>
.arrow-button {
  @import "~stylesheets/application/colors";
  background-color: $color-grey-70;
  color: $color-white;

  cursor: pointer;

  margin: 5px;
  padding: 5px 8px;

  font-size: 13px;
  font-weight: bold;

  height: 14px;

  position: relative; // for ::after selector

  user-select: none;
}

.arrow-button--right {
  margin-right: 13px;
}
.arrow-button--right::after {
  @import "~stylesheets/application/colors";

  content: "";
  position: absolute;
  left: 100%;
  top: 0;
  width: 0;
  height: 0;
  border-left: 12px solid $color-grey-70;
  border-bottom: 12px solid transparent;
  border-top: 12px solid transparent;
  clear: both;
}

.arrow-button--left {
  margin-left: 13px;
}
.arrow-button--left::after {
  @import "~stylesheets/application/colors";

  content: "";
  position: absolute;
  right: 100%;
  top: 0;
  width: 0;
  height: 0;
  border-right: 12px solid $color-grey-70;
  border-bottom: 12px solid transparent;
  border-top: 12px solid transparent;
  clear: both;
}

.arrow-button--disabled {
  @import "~stylesheets/application/colors";
  color: $color-grey-50;
  cursor: not-allowed;
}

.arrow-button:hover {
  @import "~stylesheets/application/colors";
  $_hover-color: darken($color-grey-70, 10%);

  background-color: $_hover-color;

  &.arrow-button--left::after {
    border-right: 12px solid $_hover-color;
  }
  &.arrow-button--right::after {
    border-left: 12px solid $_hover-color;
  }
}
</style>

<script>
import { computed, ref, toRefs, unref } from "vue";
import jQuery from "jquery";

export default {
  props: {
    slides: {
      type: Array,
      default: [],
    },
  },
  setup(props) {
    const { slides } = toRefs(props);

    const rootElement = ref(null);

    const currentPosition = ref(0);
    const currentSlide = computed(() => {
      if (
        unref(currentPosition) < 0 ||
        unref(currentPosition) >= unref(slides).length
      ) {
        return null;
      }

      return unref(slides)[unref(currentPosition)];
    });

    const isFullscreen = ref(false);
    jQuery(document).on("fullscreenchange", () => {
      if (document.fullscreenElement) {
        isFullscreen.value = true;
      } else {
        isFullscreen.value = false;
      }
    });
    const toggleFullscreen = () => {
      if (unref(isFullscreen)) {
        document.exitFullscreen();
      } else {
        unref(rootElement).requestFullscreen();
      }
    };

    const navigatePrev = () => {
      if (unref(currentPosition) === 0) return;
      currentPosition.value = unref(currentPosition) - 1;
    };
    const navigateNext = () => {
      if (unref(currentPosition) === unref(slides).length - 1) return;
      currentPosition.value = unref(currentPosition) + 1;
    };

    const onNewPosition = (event) => {
      const newPosition = parseInt(event.target.value || 0, 10) - 1;
      currentPosition.value = null; // Trigger rerendering even if the value did not change
      currentPosition.value = Math.max(
        Math.min(unref(slides).length - 1, newPosition),
        0
      );
    };

    return {
      currentSlide,
      currentPosition,
      slidesProp: slides,
      toggleFullscreen,
      rootElement,
      navigatePrev,
      navigateNext,
      isFullscreen,
      onNewPosition,
    };
  },
};
</script>
