import { ref, unref } from "vue";

// returns a counter that increases every so often
// (defined by the argument in milliseconds)
// and is reactive and thus can be watched to
// periodically do stuff
export default function(intervalInMS) {
  const tick = ref(0);

  let currentTimeout = null;

  const doTick = () => {
    tick.value = unref(tick) + 1;
    if(currentTimeout) {
      clearTimeout(currentTimeout);
      currentTimeout = null;
    }
    currentTimeout = setTimeout(doTick, intervalInMS);
  };

  const cancelTick = () => {
    if(currentTimeout) {
      clearTimeout(currentTimeout);
      currentTimeout = null;
    }
  }

  doTick();

  return {
    tick,
    cancelTick,
  };
};
