import Axios from "axios";
// import { objectToFormData } from "object-to-formdata";

import getMetaDataTag from "./get-meta-tag";

export default async function(path, method, payload, extraOptions = {}) {
  const url = path;
  let data = payload;
  let contentType = "application/json"; // We send json by default

  // Request options
  const options = {
    headers: {
      "Content-Type": contentType,
      "X-CSRF-Token": getCSRFToken(),
    },
    method,
    url,
  }

  if(method.toUpperCase() == 'GET') {
    options.params = payload;
    delete options.headers["Content-Type"];
  } else {
    options.data = data;
  }

  console.debug("Calling " + method.toUpperCase() + " " + path, payload);

  return await Axios({
    ...options,
    ...extraOptions,
  });

};

// Source:
// https://github.com/supercrabtree/interpolate-url/blob/da093fb9bc9e4351a09acf6b64c2099bf287631c/index.js
const interpolateUrl = (
  url,
  params,
) => {
  if (url !== undefined && url !== null && typeof url !== "string") {
    throw new Error("url must be a string");
  }

  const result = [];

  (url || "").split("/:").forEach((segment, i, arr) => {
    if (i === 0) {
      result.push(segment);
    } else {
      const segmentMatch = segment.match(/(\w+)(?:[?*])?(.*)/) || [];
      const key = segmentMatch[1] || "";

      if (params[key] !== undefined) {
        result.push("/" + params[key]);
      } else {
        result.push("/:" + key);
      }

      result.push(segmentMatch[2] || "");
    }
  });

  return result.join("");
};

export {
  interpolateUrl
}

const getCSRFToken = function() {
  return getMetaDataTag('csrf-token');
}
