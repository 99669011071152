import jQuery from "jquery";

export default function(open_button_selector, form_selector) {
  jQuery(document).on('turbo:load', () => {
    const openButton = jQuery(open_button_selector);
    const form = jQuery(form_selector);
    const searchField = form.find('input');

    if(!searchField.val()) {
      openButton.show();
      form.hide();
    }

    openButton.on('click', () => {
      openButton.hide();
      form.show();
      searchField.trigger('focus');
    })

    form.on('focusout', () => {
      if(!searchField.val()) {
        form.hide();
        openButton.show();
      }
    });
  })
}
