import ActionTextCustomizer from "./action-text-customizer";

export default class ActionTextFix {
  #tagName = 'trix-editor';
  #values = null;

  constructor() {
    this.#values = [];
  }

  prepareValues() {
    for(const trixTag of document.getElementsByTagName(this.#tagName)) {
      const inputTagSelector = '#' + trixTag.getAttribute('input');
      const inputTag = document.querySelector(inputTagSelector);
      this.#values.push({
        inputTagSelector,
        originalValue: inputTag.value,
      });

      // set v-pre
      inputTag.setAttribute('v-pre', "");
    }
  }

  restoreValues() {
    for(const element of this.#values) {
      const inputTag = document.querySelector(element.inputTagSelector);
      inputTag.value = element.originalValue;
      inputTag.setAttribute('v-pre', "");
    }
  }

  startActionText() {
    this.restoreValues();
    this._start();
  }

  prepareApp(app) {
    console.debug("Using Vue 3 customElements for fixing Rail's ActionText");

    const predicate = (tag) => tag.startsWith('trix-');
    if(app.config.isCustomElement) {
      const original = app.config.isCustomElement;
      app.config.isCustomElement = (tag) => {
        return original(tag) || predicate(tag);
      }
    } else {
      app.config.isCustomElement = predicate;
    }
  }

  _start() {
    const Trix = require("trix");

    // load actiontext library
    require("@rails/actiontext");

    // customize actiontext library
    const customizer = new ActionTextCustomizer(Trix);
    customizer.customize();
  }
}

function startActionText() {
}
